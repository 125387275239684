import { Component, ElementRef, OnInit, ViewChild,} from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { AppService } from 'src/app/app.service';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import metaData from 'src/assets/data/default/header-footer.json';
import metaData_vimpro from 'src/assets/data/vimpro/header-footer.json';
import {config} from 'src/configs/config';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { ProductListingService } from 'src/app/Lazy-loaded-Modules/Product-Modules/product-lists/product-listing/product-listing.service';
import { log } from 'console';
import { LoginService } from 'src/app/Lazy-loaded-Modules/Account-Modules/login-new/login-new.service';
@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

	under_carriage: boolean = true;
	submenu_shown : boolean = false;

	public mainMenuToggle = false;

	allCategories = [];
	dynamicMenuItems = {};

	allMenuItem = metaData.menu;
	allMenuItem_vimpro = metaData_vimpro.menu;
	config = config;
	categoryNameList = [];
	categoryListObj = [];
	categorysubmenu=[];
	activeRoute: any;
	public outletId;
	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private localService: LocalStorageService,
		private listingService: ProductListingService,
		private LoginService:LoginService
	) { }


	ngOnInit(): void {
		console.log("this.route.urllllllllllllllllllllllllllllllllllll",this.appService.outletId)
		console.log("this.route.ukkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",this.localService.get('karupattirajaOutletId'))
		this.outletId = this.appService.outletId;
		if(this.outletId==1){
			this.outletId = this.localService.get('karupattirajaOutletId');
		  }
		if(this.config.title=="Karupattiraja"){
			if(this.outletId=="0"|| this.outletId==0||this.outletId==""|| this.outletId==null){
			  this.outletId=1;
			}
			
		  }
		this.getAllCategories();
	}

	navMainMenuToggle(): void {
		this.mainMenuToggle = !this.mainMenuToggle;
	}
	showSubmenu(){
		if(this.submenu_shown==false){
		document.getElementById("nav-showmenu").style.display = "block"
		this.submenu_shown=true;
		}
		else if(this.submenu_shown){
		document.getElementById("nav-showmenu").style.display = "none"
		this.submenu_shown=false;
		}
	}
	toggletNavActive(item,link) {
		if(config.title === "ShubhCards" || config.title === "Deera" || config.title === "BestGifts"){
			//  this.localService.set('is_theme_filter','true');
			// this.localService.set('theme_filter_name',item.name);
			
			this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[item.name],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
			document.getElementById("nav-showmenu").style.display = "none"
			this.submenu_shown=false
	
		}
		else
		if(config.title === "BombayHardware" || config.title === "AvonSeals"|| config.title === "Karupattiraja"){
			//  this.localService.set('is_theme_filter','true');
			// this.localService.set('theme_filter_name',item.name);
			
			this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[item.name],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
	
		}
		
		else if (config.title === "Nesma")
		{
			this.router.navigate([`/product-listing`], { queryParams: { terms: [item.name, 'Category'],menutype:link, type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });

		}
		else if (config.title === "BBold")
		{
			this.router.navigate([`/product`], {queryParams: { terms:[item.name, 'Category'], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });

		}
		else
		{
			this.router.navigate([`/productListing`], { queryParams: { terms: [item.name], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
	
		}
			item.active = !item.active;
	}

	categoryListing(categoryName){
		this.router.navigate([`/productListing`], { queryParams: { terms: [categoryName, 'Category'], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
	  }

	toggletMainActive(item) {
		
		if(config.title === "Murgasen"){
			this.router.navigate([`/productListing`], {queryParams: {type: "last90days"}, relativeTo:this.route,  skipLocationChange: false });
		}
		else if(config.title === "ShubhCards" || config.title === "Deera" || config.title === "BestGifts" || config.title === "Vimpro"){
			this.localService.set('is_clear_data','true')
			console.log("datataa")
			this.listingService.updateAdminFormEvent('clearAll');
			this.localService.set('allProducts','all')
			this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
	
			
	
		}


		else 
		if (config.title === 'Carience'|| config.title === "Karupattiraja") {
			let userId = this.localService.get('UserId');
		 if(userId){
		 // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
		 this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
		 }
		 else{
			this.LoginService.updateAdminFormEvent({name: "ask_login"});
			this.router.navigate([`/login-new`], { skipLocationChange: false });
			//this.router.navigate([`/login-new`], { skipLocationChange: false });
		 }
		}
		else if(config.title === "BombayHardware" || config.title === "AvonSeals" ||config.title === "Cle"){
			// this.router.navigate([`/productListing`], {queryParams: { terms:[[],['GI'],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
			this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
		}
		else{
			this.router.navigate([`/productListing`], { queryParams: { terms: [item.title], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
			item.active = !item.active;
		}

	}

	getAllCategories() {

		let reqData = {
			"domain_name": ORG_DETAILS.DOMAIN_NAME,
			"user_id": null,
			"extras": {
				"find": {
					"outlet_id":Number(this.outletId),
				},
				"pagination": false,
				"paginationDetails": {
					"limit": 1,
					"pagesize": 25
				},
				"sorting": true,
				"sortingDetails": {
					"sortfield": "sort_order",
					"sortorder": -1
				}
			}
		}
		this.appService.postApiCall(reqData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
			resp => {

				if(resp && resp.success == 1)
				{
				this.allCategories = resp.result.data;
				this.categoryNameList = [];
	
				this.categoryListObj = [];
				let i = 0;
				let subchildren = [];
				let mainChildren = {}
				this.allCategories.forEach(ct => {
					this.categoryNameList.push(ct.name);
					this.categorysubmenu.push({"name":ct.name})
					if(i==0){
						mainChildren = {
							title: ct.name,
							type: 'sub',
							active: false
						}
						i = i + 1;
					}
					else{
						subchildren.push(
							{
								path: '',
								title: ct.name,
								type: 'link'
							}
						)
						if(i === 4){
							mainChildren["children"] = subchildren;
							this.categoryListObj.push(mainChildren);
							mainChildren = [];
							subchildren = [];
							i = 0
						}
						else{
							i = i + 1;
						}
					}
				});
				
				if(config.title === "Murgasen"){
					this.allMenuItem.forEach((amenu) => {
						if(amenu.title === "Categories"){
							amenu.children = this.categoryListObj
						}
					})
					console.log(this.allMenuItem)
				}
				if(config.title === "Nesma"){
					this.allMenuItem.forEach((amenu) => {
						if(amenu.title === "Menu"){
							amenu.children = this.categorysubmenu.filter(element=>
								
								!element.name.toLocaleLowerCase().includes('batter')
								

								
							
							
							)
						}
						if(amenu.title === "Batter"){
							amenu.children = this.categorysubmenu.filter(element=>
								
								element.name.toLocaleLowerCase().includes('batter')
								

								
							
							
							)
						}
					})
					console.log(this.allMenuItem)
				}


				this.allCategories.forEach((element)=>
				{
					if(config.title !== "Murgasen"&&config.title !== "Nesma"){
						this.allMenuItem.forEach((item)=>
					{
						if((item.title.toLocaleLowerCase())== element.name.toLocaleLowerCase())
						{
							console.log("category",element)
							item.children = element.subcategories
						}
					})
					}

				})
				}
			},
			err => {
				if (err.error.error.message) {
					console.log(err.error.error.message)
				}
				else {
					console.log('Something bad happened; Please try again!')
				}
			})
	}

	submenuFn(mtitle, stitle){

	}

	openLink(link, param)
	{
		if(config.title === 'Tams'){
			if(param['gotoPrepare']&& this.router.url == '/?gotoPrepare=true')
		{
			var elmnt = document.getElementById('how_to_prepare');
        	elmnt.scrollIntoView();
		}
		}
    console.log("link "+link)
	
   // console.log("param "+JSON.stringify(this.route))
   if(config.title==='Nesma')
   {
	
	   if(link=='/menu'||link=='/batter')
	   {
		this.router.navigate(['/product-listing'], {queryParams: {menutype:link}, relativeTo:this.route,  skipLocationChange: false });
	
	   }
	   else{
		this.router.navigate([link], {queryParams:param, relativeTo:this.route,  skipLocationChange: false });
	
	   }
		
	
	//this.router.navigate([`/product-listing`], { queryParams: { terms: ["",""], category: [], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
  
   }
   else  if(config.title=='Beaubelle'){
	if(link=='/Beaubelle'){
		this.router.navigate(['/']).then(()=>{
			this.router.navigate([`/productListing`], {queryParams: { terms:[['Beaubelle'],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
		//this.router.navigate([`/productListing`], { queryParams: { terms: ['Beaubelle'], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });

		

	})
	}
	else if(link=='/B&B Labs'){
		this.router.navigate(['/']).then(()=>{
			this.router.navigate([`/productListing`], {queryParams: { terms:[['B&B Labs'],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

		//this.router.navigate([`/productListing`], { queryParams: { terms: ['B&B Labs'], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
		})


	}
	else if(link=='/MC Cosmetics'){
		this.router.navigate(['/']).then(()=>{
			this.router.navigate([`/productListing`], {queryParams: { terms:[['MC Cosmetics'],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
        //this.router.navigate([`/productListing`], { queryParams: { terms: ['MC Cosmetics'], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
	})
	}
	else if(link=='/productListing'){
		this.router.navigate(['/']).then(()=>{
			this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

	
	})}
	else if(link=='/contact-us'){
		this.router.navigate(['/']).then(()=>{
		this.router.navigate([`/contact-us`]);
	
	})}
	else if(link=='/about-us'){
		this.router.navigate(['/']).then(()=>{
		this.router.navigate([`/about-us`]);
	
	})}
	else if(link=='/investor'){
		this.router.navigate(['/']).then(()=>{
		this.router.navigate([`/investor`]);
	
	})}
	else if(link=='/doct_talk'){
		this.router.navigate(['/']).then(()=>{
		this.router.navigate([`/doct_talk`]);
		
		
	
	})}
	else if(link=='/'){
		this.router.navigate(['/']).then(()=>{
		this.router.navigate([`/`]);
	
	})}
   }

   else{
	if(link==="/productListing" && (config.title==="BombayHardware" || config.title==="AvonSeals" || config.title==="Cle")){
		console.log("OPENlink")
		this.localService.set('allProducts','all')
	    // this.router.navigate([`/productListing`], {queryParams: { terms:[[],['GI'],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });	
		this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });	

	}
	else {
		if(this.config.title=='Mayan'){
			this.router.navigate([link], {queryParams: param, relativeTo:this.route,  skipLocationChange: false });
			this.mainMenuToggle = !this.mainMenuToggle;
		}
		
		
		this.router.navigate([link], {queryParams: param, relativeTo:this.route,  skipLocationChange: false });
		console.log("shubcards")
		if(this.config.title=='ShubhCards'){
			window.scrollTo(0, 0);

		}
		
		
		
		
	}
   }
		
	}


	nosubCategory(category) {
		if(config.title === "Murgasen"){
			if(category === "productListing"){
				this.router.navigate([category], {queryParams: {type: "last90days"}, relativeTo:this.route,  skipLocationChange: false });
			}
			else{
				this.router.navigate([category])
			}
		}
		else{
			// this.router.navigate([category]);
			this.router.navigate(['/']).then(()=>{
			this.router.navigate([category]);
		})
		}

	}

	
	productsList(){
		this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
	  }
	  topFunction() {
		
	  }

	  toggleGallery() {
         if(config.title === "Vimpro"){
            this.router.navigate([`/`], { queryParams: { type: 'gallery_sec' }, relativeTo: this.route, skipLocationChange: false });
			//window.scroll(0,4200)
		  // document.getElementById("gallery_sec").scrollIntoView();
		   this.showMenuSection("gallery_sec")
		  

            }
           }
		   showMenuSection(sectionId) {
			
			console.log("rsateytqrwytqr")
			var url1 = document.getElementById("gallery_sec")
			if(url1==null){
				setTimeout(() => {
					this.delayedFunction();
				  }, 100);

			}
			else{
				document.getElementById("gallery_sec").scrollIntoView();

			}
		
			
			 
			
		}
		delayedFunction(){
			document.getElementById("gallery_sec").scrollIntoView();


		}
			
// 		   toggleGalleryMobile() {
// 	 	if(config.title === "Vimpro"){
// 			  this.router.navigate([`/`], { queryParams: { type: 'gallery_sec' }, relativeTo: this.route, skipLocationChange: false });
// 			  window.scroll(0,4200)
// 		 	   }
// 			  }
 }
